/* General Styles */
header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: auto;
  max-height: 100vh;
  position: relative;
  padding: 2rem;
  margin: auto;
  max-width: 1200px;
}

/* =============== CTA ============== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* =============== HEADER SOCIALS ============== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 14.3rem; /* Reduced by 35% from 22rem */
  height: 14.3rem; /* Ensure the container remains a square */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 4rem;
  border-radius: 50%; /* Makes the container a circle */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.me img {
  width: 100%;
  height: auto; /* Ensures the image keeps its aspect ratio */
  object-fit: contain; /* Ensures the entire image is visible within the container */
  border-radius: 50%; /* Ensures the image is circular */
}

/* =============== SCROLL DOWN ============== */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
    padding-top: 4rem;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }

  .me {
    width: 9.75rem; /* Reduced by 35% from the original */
    height: 9.75rem; /* Ensure the container remains a square */
    margin-top: 2.5rem;
    justify-content: center;
    border-radius: 50%; /* Ensure it's a circle */
  }

  .me img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Show the full image within the circle */
    border-radius: 50%; /* Ensure the image is circular */
  }

  .cta {
    margin-top: 1.7rem;
    gap: 1rem;
  }
}
